import './Footer.scss';

export const Footer=()=>{
    let today = new Date();
    let year = today.getFullYear();
    return(
        <footer className="footer">
            <p>&copy; {year} ENVOQ IN.</p>
        </footer>
    )
}