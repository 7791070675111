import { Link } from "@reach/router";
import Logo from 'jsx:../../assets/images/svg/logo_name.svg';
import NavLeftArrow from 'jsx:../../assets/images/svg/arrow_left.svg';
import NavRightArrow from 'jsx:../../assets/images/svg/arrow_right.svg';
import { useState } from "react";

import './NavBar.scss';

const NavBar=(props)=>{
    const [menu, setMenu] = useState(false);
    return(
        <header style={{width:'100%'}}>
            <div className='' style={{width:'100%', height:'65px', position:'relative', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                {/* <div style={{width:'' display:'flex', justifyContent:'center', alignItems:'center'}}> */}
                    {
                        props.windowSize.innerWidth>600?
                            <Link to='/'>
                                <div style={{
                                    width:'200px', 
                                    height:'65px',  
                                    display:'flex', 
                                    justifyContent:'center', 
                                    alignItems:'center', 
                                    // background:'rgba(235,235,235,0.2)',
                                }}>
                                    <Logo />
                                </div>
                            </Link>
                        :
                            menu? 
                                null
                            : 
                                <Link to='/'>
                                    <div style={{
                                        width:'200px', 
                                        height:'65px',  
                                        display:'flex', 
                                        justifyContent:'center', 
                                        alignItems:'center', 
                                        // background:'rgba(235,235,235,0.2)',
                                    }}>
                                        <Logo />
                                    </div>
                                </Link>
                    }
                    {/* <span>ENVOQ</span> */}
                {/* </div> */}
                
                {
                    props.windowSize.innerWidth>600?
                        // if the menu is going to be in the same page
                        <nav style={{ marginRight:'50px', width:'300px', height:'100%', display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                            <Link className="nav-link" to='about'>About</Link>
                            <Link className="nav-link" to='contact'>Contact</Link>
                            <Link className="nav-link" to='career'>Career</Link>
                        </nav>
                    : 
                        menu?
                            <div style={{ width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center', height:'100%'}}>
                                <NavRightArrow onClick={()=>setMenu(false)} style={{cursor:'pointer', marginLeft:'10px'}}/>
                                {/* if the menu has to be on the overflow page */}
                                <nav style={{ marginLeft:'20px', marginRight:'20px', width:'100%', height:'100%', display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                                    <Link className="nav-link" to='about'>About</Link>
                                    <Link className="nav-link" to='contact'>Contact</Link>
                                    <Link className="nav-link" to='career'>Career</Link>
                                </nav>
                            </div>
                        :
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>
                                <NavLeftArrow onClick={()=>setMenu(true)} style={{cursor:'pointer', marginRight:'10px'}}/>
                            </div>                            
                }
            </div>
        </header>
    )
}

export default NavBar;