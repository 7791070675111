import { Helmet } from "react-helmet";
import { UnderDevelopment } from "../components/UnderDevelopment/UnderDevelopment";

const Career=()=>{
    return(
        <div className="mid-part">
            <Helmet>
                <title>Career @ ENVOQ: Find Jobs | Apply Now</title>
            </Helmet>
            {/* <h1>Career Page</h1> */}
            <UnderDevelopment title='Careers | Join ENVOQ IN.'/>
        </div>
    )
}

export default Career;