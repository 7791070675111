import { Link } from "@reach/router";
import { Helmet } from "react-helmet";
import './Pages.scss';

const PageNotFound=()=>{
    return(
        <div className="mid-part">
            <Helmet>
                <title>Page Not Found | ENVOQ</title>
            </Helmet>
            <section style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'80vh'}}>
                <h2>Page Not Found - Error 404</h2>
                <div style={{ width:'250px', display:'flex', justifyContent:'space-between', alignItems:'center' }}>
                    <button className="action-button" onClick={()=>{window.history.go(-1)}}>Go back</button>
                    <Link style={{color:'#ccc'}} to='/'>Start Again</Link>
                </div>
            </section>
        </div>
    )
}

export default PageNotFound;
