import { Helmet } from "react-helmet";
import ProblemSolvers from 'jsx:../assets/images/svg/problem_solvers.svg';
import Developers from 'jsx:../assets/images/svg/developers.svg';
import Team from 'jsx:../assets/images/svg/team.svg';

import './Pages.scss';

const Home =() =>{
    return(
        <div className="mid-part">
            <Helmet>
                <title>Home | ENVOQ</title>
            </Helmet>

            <section className="full-width-banner">
                <div className="h1">
                    <span>We are</span>
                    <div className="message">
                        <div className="word1 glow" style={{color:'lightblue'}}><span>Problem solvers</span></div>
                        <div className="word2 glow" style={{color:'lightgreen'}}><span>Developers</span></div>
                        <div className="word3 glow" style={{color:'rgb(255, 185, 54)'}}><span>Building the future</span></div>
                    </div>
                </div>
                
                {/* SVG Slider */}

                <div className="svg-slider" style={{ height:'300px'}}>
                    <div className='svg-image'>
                        <ProblemSolvers />
                    </div>
                    <div className='svg-image'>
                        <Developers />
                    </div>
                    <div className='svg-image'>
                        <Team />
                    </div>
                </div>
            </section>
            {/* <h1>Home Page</h1> */}
        </div>
    )
}

export default Home;