import { Router, Link } from "@reach/router";
import Home from '../../pages/Home.js';
import About from '../../pages/About.js';
import Contact from '../../pages/Contact.js';
import Career from '../../pages/Career.js';
import PageNotFound from "../../pages/PageNotFound.js";
import NavBar from "../../components/NavBar/NavBar.js";
import { Footer } from "../../components/Footer/Footer.js";

import './App.scss';
import { useEffect, useState } from "react";

function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
}

export function App() {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
      
        window.addEventListener('resize', handleWindowResize);
      
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

    return(
        <div>
            <NavBar windowSize={windowSize}/>

            <Router>
                <Home path='/' windowSize={windowSize}/>
                <About path='about' windowSize={windowSize}/>
                <Contact path='contact' windowSize={windowSize}/>
                <Career path='career' windowSize={windowSize}/>
                <PageNotFound path='/*' windowSize={windowSize}/>
            </Router>

            <Footer />
        </div>
    );
}