import { Helmet } from "react-helmet";
import { UnderDevelopment } from "../components/UnderDevelopment/UnderDevelopment";

const Contact=()=>{
    return(
        <div className="mid-part">
            <Helmet>
                <title>Contact | ENVOQ</title>
            </Helmet>
            {/* <h1>Contact Page</h1> */}
            <UnderDevelopment title='Contact us' />
        </div>
    )
}

export default Contact;