import { Helmet } from "react-helmet";
import { UnderDevelopment } from "../components/UnderDevelopment/UnderDevelopment";

const About=()=>{
    return(
        <div className="mid-part">
            <Helmet>
                <title>About | ENVOQ</title>
            </Helmet>
            {/* <h1>About Page</h1> */}
            <UnderDevelopment title='About us' />
        </div>
    )
}

export default About;